<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Latest BPJS
            .header-cta
                .flex.items-center
                    button.btn-default(@click="openWatchlist(true)") Watchlist: 100
                        IconSet(type="chevron-down")
                    .block
                        button.btn-primary.m-btn-icon(@click="openModal(true)")
                            IconSet(type="add")
                            span.m-hide.ml-3 New BPJS

        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        type="text"
                        placeholder="Search here..."
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                    select.field-style()
                        option A
                        option B
                        option C
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )

                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    .relative.field-style.w-full
                        date-range-picker(
                            ref="picker"
                            :opens="opens"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                            :autoApply="true"
                            v-model="dateRange"
                            @toggle="logEvent('event: open', $event)"
                        )
                            div(
                                slot="input" 
                                slot-scope="picker") 
                                    | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                        .field-icon
                            IconSet(type="calendar")


            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th column 1
                            th column 2
                            th column 3
                    tbody
                        tr
                            td column 1
                            td column 2
                            td column 3   







    //- popup 

    .popup-layer.is-show(v-if="openPopUp")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl New BPJS

                button.btn-icon-40(
                    @click="openModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-2.pb-2
    
    .popup-layer.is-show(v-if="popUpWatchlist")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl WatchList

                button.btn-icon-40(
                    @click="openWatchlist(false)"
                )
                    IconSet(type="close")

            .popup-container__body
                .mb-2.pb-2
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components: {
        IconSet,
        DateRangePicker
    },

    data() {
        return {
            openPopUp: false,
            popUpWatchlist: false,
            dateRange: {
                startDate: '2022-01-01',
                endDate: '2022-01-31',
            },

            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        }
    },
    methods: {
        openModal(open = true) {
            // hide scroll bar if true
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.openPopUp = !this.openPopUp
        },

        openWatchlist(open = true) {
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.popUpWatchlist = !this.popUpWatchlist
        },
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
}
</script>